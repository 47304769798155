import { getUserToken } from "../utils/localStorage";

export const sendPublicCheaperOrder = async (order) => {
  const data = {
    comment: order.comment,
    contact: order.login,
    // eslint-disable-next-line camelcase
    contact_name: order.name,
  };

  try {
    const res = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/public/cheaper-order`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    });

    const responseData = await res.clone().json();

    if (!res.ok) {
      const errorMessage = responseData?.meta?.error || responseData?.code || "Необработанная ошибка при отправке письма";
      throw new Error(errorMessage);
    }

    return res.json();
  } catch(error) {
    // eslint-disable-next-line no-console
    console.log("error", error);
    throw error;
  }
};

export const sendPrivateCheaperOrder = async (order) => {
  const token = getUserToken();
  const data = {
    comment: order.comment,
    contact: order.login,
    // eslint-disable-next-line camelcase
    contact_name: order.name,
  };

  try {
    const res = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/private/cheaper-order`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "Authorization": `Bearer ${token}`,
      },
      body: JSON.stringify(data),
    });

    const responseData = await res.clone().json();
    if (!res.ok) {
      const errorMessage = responseData?.meta?.error || responseData?.code || "Необработанная ошибка при отправке письма";
      throw new Error(errorMessage);
    }

    return res.json();
  } catch (error) {
    // eslint-disable-next-line no-console
    console.log("error", error);
    throw error;
  }

};

export const cheaperApi = {
  sendPublicCheaperOrder,
  sendPrivateCheaperOrder,
};