import { useSelector } from "react-redux";
import { useEffect, useState } from "react";

import { productsApi } from "../../../../api/productsApi";

import CustomLink from "../../../../components/CustomLink";
import SectionTitle from "../../../../components/SectionTitle";
import GeneralProductsSlider from "../GeneralProductsSlider";
import { getErrorData } from "utils/getErrorData";

import styles from "./styles.module.scss";

const HitsBlock = () => {
  const { deviceType } = useSelector((state) => state.user);
  const [products, setProducts] = useState([]);

  useEffect(() => {
    (async () => {
      try {
        const data = await productsApi.getProductsWithLimit({
          limit: 16,
          badges: "bestseller",
        });

        if (!data?.response?.items) {
          const { message } = getErrorData(data);
          throw new Error(message);
        }

        setProducts(data.response.items ?? []);
      } catch (err) {
        // eslint-disable-next-line no-console
        console.error("Ошибка при получении товаров в разделе Хиты продаж: -->", err?.message);
      }
    })();
  }, []);

  return (
    <section className={styles.root}>
      <SectionTitle
        title={
          !deviceType.isMobile ? "Хиты продаж: наши бестселлеры" : "Хиты продаж"
        }
      />
      {!!products.length && (
        <GeneralProductsSlider
          products={products}
          isMobile={deviceType.isMobile}
          isDesktop={deviceType.isDesktop}
          isLargeDesktop={deviceType.isLargeDesktop}
          shouldAutoSwitched
          shouldBeLooped
        />
      )}
      <CustomLink
        path="/catalog?badges=bestseller"
        title="Смотреть все"
        className={styles["see-more"]}
      />
    </section>
  );
};

export default HitsBlock;
