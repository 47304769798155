import PropTypes from "prop-types";
import styles from "./styles.module.scss";
import dangerCircle from "../../assets/icons/dangerCircle.svg";

const NotificationHint = ({ message }) => {
  return (
    <div className={styles["info-danger-block"]}>
      <img className={styles["info-danger-block__icon"]} src={dangerCircle} alt="danger-circle" />
      <p className={styles["info-danger-block__message"]}>{message}</p>
    </div>
  );
};

NotificationHint.propTypes = {
  message: PropTypes.string.isRequired,
};

export default NotificationHint;