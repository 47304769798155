import { useEffect, useRef, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import PropTypes from "prop-types";

import useOutsideClick from "../../../utils/hooks/useOutsideClick";
import BrandsByLetterController from "../../../components/BrandsByLetter/BrandsByLetterController";

import cn from "classnames";
import styles from "./styles.module.scss";
import BottomSheet from "../../../components/UI/Bottomsheet/Bottomsheet";
import BrandsMobile from "../../../components/BrandsMobile/BrandsMobile";
import { useAppSelector } from "store/reduxHooks";

// если фильтр в Каталоге относится к product_properties - мы переименовываем его ключ в properties[ключ]
const NAVIGATION_TITLES = [
  {
    id: 0,
    title: "Бренды"
  },
  {
    id: 1,
    title: "Парфюмерия",
    link: "/catalog/parfumeria"
  },
  {
    id: 2,
    title: "Волосы",
    // если фильтр в Каталоге относится к product_properties - мы переименовываем его ключ в properties[ключ]
    link: "/catalog/kosmetika?properties%5Bkategoria%5D=dla-volos"
  },
  {
    id: 3,
    title: "Лицо",
    // если фильтр в Каталоге относится к product_properties - мы переименовываем его ключ в properties[ключ]
    link: "/catalog/kosmetika?properties%5Bkategoria%5D=dla-lica"
  },
  {
    id: 4,
    title: "Тело",
    // если фильтр в Каталоге относится к product_properties - мы переименовываем его ключ в properties[ключ]
    link: "/catalog/kosmetika?properties%5Bkategoria%5D=dla-tela"
  },
  {
    id: 5,
    title: "Распродажа",
    link: "/catalog?badges=sale"
  }
];

const CatalogTitles = (props) => {
  const location = useLocation();
  const [currentNavigationTitleId, setCurrentNavigationTitleId] =
    useState(null);
  const [showBrandsSelect, setShowBrandsSelect] = useState(false);
  const [isOpenBottomSheet, setIsOpenBottomSheet] = useState(false);
  const { deviceType } = useAppSelector((state) => state.user);

  const brandsRef = useRef();

  useOutsideClick({
    ref: brandsRef,
    cb: () => setShowBrandsSelect(false)
  });

  useEffect(() => {
    const foolPath = `${location.pathname}${location.search}`;
    const currentNavigationTitle = NAVIGATION_TITLES.find((item) => {
      return item.link === foolPath;
    });

    if (currentNavigationTitle) {
      setCurrentNavigationTitleId(currentNavigationTitle.id);
      return;
    }
    setCurrentNavigationTitleId(null);
  }, [location]);

  const onClickToggleNavigationTitle = (item) => {
    if (!item.link) {
      return;
    }
    setCurrentNavigationTitleId(item.id);
  };

  const toggleBrandsSelectStatus = (item) => {
    if (deviceType.isMobile || deviceType.isTablet)
      return setIsOpenBottomSheet(true);
    onClickToggleNavigationTitle(item);
    setShowBrandsSelect(!showBrandsSelect);
  };

  const onCloseBrandsSelect = () => {
    setShowBrandsSelect(false);
  };

  const showButton = (item) => {
    if (!item.link) return true;
  };

  return (
    <>
      <nav className={cn(styles.root, props.containerStyles)}>
        <ul className={styles.list}>
          {NAVIGATION_TITLES.map((item) => {
            return (
              <li
                key={item.id}
                className={cn(styles.list__item, {
                  [styles["list__item--active"]]:
                    currentNavigationTitleId === item.id
                })}
              >
                {showButton(item) ? (
                  <button onClick={() => toggleBrandsSelectStatus(item)}>
                    {item.title}
                  </button>
                ) : (
                  <Link
                    to={item.link}
                    onClick={() => onClickToggleNavigationTitle(item)}
                  >
                    {item.title}
                  </Link>
                )}
              </li>
            );
          })}
        </ul>
        {showBrandsSelect && (
          <BrandsByLetterController
            modalRef={brandsRef}
            onClose={onCloseBrandsSelect}
          />
        )}
      </nav>
      <BottomSheet
        isOpen={isOpenBottomSheet}
        onClose={() => setIsOpenBottomSheet(false)}
      >
        <BrandsMobile onClose={() => setIsOpenBottomSheet(false)} />
      </BottomSheet>
    </>
  );
};

const propTypes = {
  containerStyles: PropTypes.string
};

CatalogTitles.propTypes = propTypes;

export default CatalogTitles;
