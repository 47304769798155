import { useEffect, useMemo, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { getUserFavoriteProducts } from "../../../../store/user/userThunks/userThunks";

import SelectedProducts from "./SelectedProducts";
import AnchorsBlock from "../components/AnchorsBlock";

import styles from "./styles.module.scss";
import { getErrorData } from "utils/getErrorData";
import LoadingItem from "components/LoadingItem/LoadingItem";

const UserSelectedProducts = () => {
  const { deviceType } = useSelector((state) => state.user);
  const [, setUpdate] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  const dispatch = useDispatch();

  const favouriteProductsRef = useRef([]);
  const subscriptionProductsRef = useRef([]);
  const favouritesAnchorRef = useRef(null);
  const subscriptionAnchorRef = useRef(null);

  const anchors = useMemo(() => {
    return [
      {
        id: 0,
        title: "Товары",
        ref: favouritesAnchorRef,
        offsetTop: 200,
      },
      {
        id: 1,
        title: "Подписка на товары",
        ref: subscriptionAnchorRef,
        offsetTop: 90,
      },
    ];
  }, []);

  useEffect(() => {
    setIsLoading(true);
    dispatch(getUserFavoriteProducts()).unwrap().then((favouriteProducts) => {
      if (favouriteProducts && favouriteProducts.length > 0) {
        favouriteProductsRef.current = favouriteProducts.map((item) => item.product);
        setUpdate((prev) => !prev);
      }
      setIsLoading(false);
    }).catch((error) => {
      const errorData = getErrorData(error);
      // eslint-disable-next-line no-console
      console.error("Ошибка при получении избранных продуктов пользователя:", errorData.message);
      setIsLoading(false);
    });
  }, [dispatch]);

  return (
    <section className={styles.root}>
      <section className={styles["products-wrapper"]}>
        {isLoading ? (
          <LoadingItem
            containerStyles={styles.loading}
            titleStyles={styles.loading__title}
          />
        ) : (
          <>
            {!favouriteProductsRef.current.length &&
              !subscriptionProductsRef.current.length && (
              <h3 className={styles.title}>Еще ничего не добавлено</h3>
            )}
            {!!favouriteProductsRef.current.length && (
              <SelectedProducts
                ref={favouritesAnchorRef}
                title="Товары"
                products={favouriteProductsRef.current.flat()}
              />
            )}
            {!!subscriptionProductsRef.current.length && (
              <SelectedProducts
                ref={subscriptionAnchorRef}
                title="Подписка на товары"
                products={subscriptionProductsRef.current.flat()}
              />
            )}
          </>
        )}
      </section>
      <div id="diginetica_rec_sys"></div>
      {!!favouriteProductsRef.current.length &&
        !!subscriptionProductsRef.current.length &&
        !deviceType.isMobile && <AnchorsBlock anchors={anchors} />}
    </section>
  );
};

export default UserSelectedProducts;

