import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";

import { deliveryApi } from "../../api/deliveryApi";
import useDebounce from "../../utils/hooks/useDebounce";
import { setDeliveryCoordinates, setOrderCity, setUserDeliveryAddress } from "../../store/user/user";
import { getCityBySearchAddress } from "../ymapsUtils";
import { getErrorData } from "utils/getErrorData";

const useDelivery = (options) => {
  const { currentCity, checkoutData } = useSelector((state) => state.user);
  const [deliveryAddresses, setDeliveryAddresses] = useState([]);
  const {
    searchValue,
    isFocusInput,
    setSearchValue,
    city,
  } = options;
  const [isLoading, setIsLoading] = useState(false);
  const [isAddressesNotFound, setIsAddressesNotFound] = useState(false);

  const debouncedValue = useDebounce(searchValue, 500);
  const dispatch = useDispatch();

  useEffect(() => {
    if (!isFocusInput || searchValue.length < 3) return;
    (async() => {
      try {
        setIsLoading(true);
        const options = {
          query: searchValue,
          city: city || checkoutData.orderCity || currentCity,
          lat: "",
          lon: "",
        };
        const data = await deliveryApi.getDeliveryAddress(options);
        if ("message" in data) {
          const errorMessage = getErrorData(data);
          setDeliveryAddresses([]);
          setIsAddressesNotFound(true);
          throw new Error(errorMessage.message);
        }

        if (!data.response.items.length) {
          setDeliveryAddresses([]);
          setIsAddressesNotFound(true);
          throw new Error("Ни одного адреса по запросу не было найдено");
        }
        const addresses = data.response.items.map(item => item.value);
        setIsAddressesNotFound(false);
        setDeliveryAddresses(addresses);
      } catch(err) {
        setDeliveryAddresses([]);
        const errorData = getErrorData(err);
        // eslint-disable-next-line no-console
        console.error(errorData.message);
      } finally {
        setIsLoading(false);
      }
    })();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isFocusInput, debouncedValue, city]);

  const setDeliveryAddressByPosition = async() => {
    if (!navigator.geolocation) {
      return toast("Не можем определить ваше местоположение");
    }

    navigator.geolocation.getCurrentPosition(
      async(position) => {
        try {
          const options = {
            query: "",
            city: "",
            lat: position.coords.latitude,
            lon: position.coords.longitude,
          };
          dispatch(setDeliveryCoordinates([position.coords.latitude, position.coords.longitude]));

          const data = await deliveryApi.getDeliveryAddress(options);

          if (data.meta.error) {
            return toast(data.meta.error);
          }

          if (!data.response.items.length) {
            setDeliveryAddresses([]);
            return;
          }
          const firstAddress = data.response.items[0].value;

          setDeliveryAddresses(data.response.items.map(item => item.value));
          dispatch(setUserDeliveryAddress(firstAddress));
          setSearchValue(firstAddress);
          try {
            const [city] = await getCityBySearchAddress(firstAddress);
            if (city) {
              dispatch(setOrderCity(city));
            }
          } catch (err) {
            // eslint-disable-next-line no-console
            console.error("Произошла ошибка при поиске города:", err);
            toast("Не удалось определить город по адресу");
          }
        } catch(err) {
          // TODO
          // eslint-disable-next-line no-console
          console.error("Произошла ошибка при поиске города:", err);

        }
      },
      (error) => {
        switch (error.code) {
        case error.PERMISSION_DENIED:
          toast("Необходимо дать разрешение на определение местоположения");
          break;
        case error.POSITION_UNAVAILABLE:
          toast("Не можем определить ваше местоположение");
          break;
        case error.TIMEOUT:
          toast("Не можем определить ваше местоположение");
          break;
        default:
          toast("Не можем определить ваше местоположение");
          break;
        }
      }
    );
  };

  return {
    deliveryAddresses,
    setDeliveryAddressByPosition,
    isLoading,
    isAddressesNotFound
  };
};

export default useDelivery;