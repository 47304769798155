/* eslint-disable no-unused-vars */
import { useMemo } from "react";
import { useSelector } from "react-redux";
import PropTypes from "prop-types";

import cn from "classnames";
import styles from "./styles.module.scss";

const SocialMediaLink = (props) => {
  const { user } = useSelector((state) => state.user);

  const linkTitle = useMemo(() => {
    const userData = user.profiles.find((item) => {
      return item.service === props.service;
    });

    if (!userData) {
      return "";
    }

    return `${userData.surname ?? ""} ${userData.name} ${userData.patronymic ?? ""}`.trim();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.service]);

  const currentHref = useMemo(() => {
    if (linkTitle || !props.vendorName) {
      return;
    }

    return `${process.env.REACT_APP_BACKEND_URL}/api/public/login/vendor/${props.vendorName}`;
  }, [props.vendorName, linkTitle]);

  return(
    <a
      className={cn(
        styles.link,
        {[styles["link--activated"]]: !!linkTitle}
      )}
      href={currentHref}
      target="_self"
      rel="noopener noreferrer"
    >
      {linkTitle || "Привязать"}
    </a>
  );
};

const propTypes = {
  service: PropTypes.string,
  vendorName: PropTypes.string,
};

SocialMediaLink.propTypes = propTypes;

export default SocialMediaLink;