import { forwardRef } from "react";
import PropTypes from "prop-types";

import SelectedProductItem from "../SelectedProductItem";

import styles from "./styles.module.scss";

const SelectedProducts = forwardRef(function SelectedProducts(props, ref) {

  return (
    <div ref={ref} className={styles.root}>
      <h3 className={styles.title}>{props.title}</h3>
      {props.products.map((item, idx) => {
        return (
          <SelectedProductItem
            key={`${item.id}-${idx}`}
            item={item}
            isFavorite={props.title === "Избранное"}
          />
        );
      })}
    </div>
  );
});

const propTypes = {
  title: PropTypes.string.isRequired,
  products: PropTypes.arrayOf(PropTypes.object).isRequired,
};

SelectedProducts.propTypes = propTypes;

export default SelectedProducts;

