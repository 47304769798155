import { useMemo, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

import { getUserCart } from "../../../../store/user/cartThunks/cartThunks";
import { getUserToken, setValueInStorage } from "../../../../utils/localStorage";
import { setCheckoutItems, setPromocode } from "../../../../store/user/user";

import OrderStringItem from "../../../../components/UI/OrderStringItem";
import CustomButton from "../../../../components/CustomButton";
import PromocodeInput from "../../../../components/UI/PromocodeInput";
import PromocodeModal from "../PromocodeModal";

import styles from "./styles.module.scss";
import { formatPrice } from "utils/helpers/formatedPrice";

const OrderData = () => {
  const { user, cart, checkoutData } = useSelector((state) => state.user);
  const [inputValue, setInputValue] = useState(checkoutData.promoCode);
  const [showModal, setShowModal] = useState(false);
  const [isPromocodeSelected, setIsPromocodeSelected] = useState(!!checkoutData.promoCode);
  const [isNotValidCode, setIsNotValidCode] = useState("");
  const token = getUserToken();

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const modalRef = useRef(null);

  const productsAmount = useMemo(() => {
    return cart.reduce((acc, cur) => {
      acc = acc + cur.count;
      return acc;
    }, 0);
  }, [cart]);

  const selectedProductsAmount = useMemo(() => {
    return cart.reduce((acc, cur) => {
      if (cur.isSelected) {
        acc = acc + cur.count;
      }

      return acc;
    }, 0);
  }, [cart]);

  const productsMainPrice = useMemo(() => {
    return cart.reduce((acc, cur) => {
      if (cur.isSelected) {
        acc += cur.offer.price * cur.count;
      }

      return acc;
    }, 0);
  }, [cart]);

  const productsShopDiscount = useMemo(() => {
    return cart.reduce((acc, cur) => {
      if (cur.isSelected) {
        acc += (cur.offer.price * cur.count) - ((cur.offer.price_sale || cur.offer.akcia) * cur.count);
      }

      return acc;
    }, 0);
  }, [cart]);

  const productsPromoDiscount = useMemo(() => {
    return cart.reduce((acc, cur) => {
      if (cur.used_promo_code && cur.isSelected) {
        acc += cur.discount_by_promo_code;
      }

      return acc;
    }, 0);
  }, [cart]);

  const resultPrice = useMemo(() => {
    return cart.reduce((acc, cur) => {
      if (cur.isSelected) {
        acc += cur.calculated_amount;
      }

      return acc;
    }, 0);
  }, [cart]);

  const onChangeHandler = (e) => {
    setInputValue(e.target.value);
  };

  const onSendPromocode = async () => {
    if (!user.phone) {
      setShowModal(true);
      return;
    }

    if (!inputValue || !cart.length) {
      return;
    }

    try {
      const selectedProductIds = cart.reduce((acc, cur) => {
        if (cur.isSelected) {
          acc.push(cur.offer.id);
        }
        return acc;
      }, []);

      const options = {
        "calculate_orders": selectedProductIds.join(","),
        "use_promo_code": inputValue.trim(),
      };

      const res = await dispatch(getUserCart(options)).unwrap();

      if (!res || !res.meta) {
        return toast("Извините, произошла ошибка при попытке применить промокод. Перезагрузите страницу или попробуйте применить позже.");
      }

      setIsNotValidCode(res.meta?.error);
      if (!res.meta.error) {
        setIsPromocodeSelected(true);
        dispatch(setPromocode(inputValue));
      }
    } catch (err) {
      // TODO
    }
  };

  const onRemovePromocode = async () => {
    try {
      const selectedProductIds = cart.reduce((acc, cur) => {
        if (cur.isSelected) {
          acc.push(cur.offer.id);
        }
        return acc;
      }, []);

      const options = {
        "calculate_orders": selectedProductIds.join(","),
      };

      const res = await dispatch(getUserCart(options)).unwrap();

      if (!res || !res.meta) {
        return toast("Извините, произошла ошибка при попытке удалить промокод. Перезагрузите страницу или попробуйте применить позже.");
      }

      setIsNotValidCode(res.meta?.error);
      if (!res.meta.error) {
        setIsPromocodeSelected(false);
        setInputValue("");
        dispatch(setPromocode(""));
      }
    } catch (err) {
      // TODO
    }
  };

  const onCheckoutItems = () => {
    const checkoutItemsIds = cart.reduce((acc, cur) => {
      if (cur.isSelected) {
        acc.push(cur.offer.id);
      }
      return acc;
    }, []);

    if (!checkoutItemsIds.length) {
      return toast("Перед оформлением выберите товары");
    }

    setValueInStorage({
      key: "checkoutItemsIds",
      value: JSON.stringify(checkoutItemsIds),
    });
    dispatch(setCheckoutItems(checkoutItemsIds));
    navigate("checkout");
  };

  return (
    <div className={styles.root}>
      <div className={styles["title-wrapper"]}>
        <h3 className={styles.title}>Ваш заказ</h3>
      </div>
      <div className={styles["amount-wrapper"]}>
        <OrderStringItem
          title="Всего товаров в корзине:"
          subtitle={`${productsAmount}`}
          contanerClassname={styles.amount}
          subtitleClassname={styles["text-black"]}
        />
        <OrderStringItem
          title="Выбранные товары:"
          subtitle={`${selectedProductsAmount}`}
        />
      </div>
      {token && <PromocodeInput
        inputValue={inputValue}
        isNotValidCode={isNotValidCode}
        setIsNotValidCode={setIsNotValidCode}
        onChange={onChangeHandler}
        onSendPromocode={onSendPromocode}
        isPromocodeSelected={isPromocodeSelected}
        onRemovePromocode={onRemovePromocode}
      />
      }
      <div className={styles["price-wrapper"]}>
        <OrderStringItem
          title="Стоимость товаров:"
          subtitle={`${formatPrice(productsMainPrice) || 0} ₽`}
          contanerClassname={styles.price}
        />
        <OrderStringItem
          title="Скидка магазина:"
          subtitle={`${formatPrice(productsShopDiscount) || 0} ₽`}
          contanerClassname={styles.price}
          subtitleClassname={styles["text-black"]}
        />
        {token && <OrderStringItem
          title="Скидка по промокоду:"
          subtitle={`${formatPrice(productsPromoDiscount) || 0} ₽`}
          subtitleClassname={styles["text-black"]}
        />
        }
      </div>
      <OrderStringItem
        title="Всего к оплате:"
        subtitle={`${formatPrice(resultPrice)} ₽`}
        contanerClassname={styles.data__price}
      />
      <CustomButton
        title="Перейти к оформлению"
        className={styles.link}
        onClick={onCheckoutItems}
      />
      {showModal &&
        <PromocodeModal
          isOpen={showModal}
          modalRef={modalRef}
          onClose={() => setShowModal(false)}
        />
      }
    </div >
  );
};

export default OrderData;
