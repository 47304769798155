import { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import Checkbox from "../../../../../components/UI/Checkbox/Checkbox";
import UserContantsFrom from "./components/UserContantsFrom";

import styles from "./styles.module.scss";
import { getUserToken } from "../../../../../utils/localStorage";
import CustomButton from "../../../../../components/CustomButton/CustomButton";
import { setRecipient } from "../../../../../store/user/user";

const UserContacts = () => {
  const { user, checkoutData } = useSelector((state) => state.user);
  const token = getUserToken();
  const [showForm, setShowForm] = useState(true);
  const [anotherPerson, setAnotherPerson] = useState(
    {
      surname: "",
      name: "",
      phone: "",
      email: "",
    }
  );
  const [isAnotherPerson, setIsAnotherPerson] = useState(!token);
  const dispatch = useDispatch();

  const userPhoneNumber = useMemo(() => {
    if (!user.phone) {
      return "";
    }
    const phoneFirstPartNumbers = `+ ${user.phone[0]} (${user.phone.slice(1, 4)}) ${user.phone.slice(4, 7)}`;
    const phoneLastPartNumbers = `${user.phone.slice(7, 9)}-${user.phone.slice(9, 11)}`;
    return `${phoneFirstPartNumbers}-${phoneLastPartNumbers}`;
  }, [user.phone]);

  const onShowForm = () => {
    setIsAnotherPerson(!isAnotherPerson);
  };

  useEffect(() => {
    !isAnotherPerson ? dispatch(setRecipient(user)) : dispatch(setRecipient(anotherPerson));
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAnotherPerson]);

  return (
    <div className={styles.root}>
      <div className={styles["title-wrapper"]}>
        <span className={styles.step}>
          1/2
        </span>
        <h4 className={styles.title}>
          Контактные данные
        </h4>
      </div>
      <div className={styles.data}>
        {!!user.name &&
          <p className={styles.data__text}>
            {`${user.surname ?? ""} `}
            {`${user.name}`}
            {` ${user.patronymic ?? ""}`}
          </p>
        }

        {!!userPhoneNumber &&
          <p className={styles.data__text}>
            {`${userPhoneNumber}`}
          </p>
        }

        {!!user.email &&
          <p className={styles.data__text}>
            {user.email}
          </p>
        }
        {token && <button
          onClick={onShowForm}
          className={styles.button}
        >
          <Checkbox
            isSelected={isAnotherPerson}
            setIsSelected={setIsAnotherPerson}
            containerClassname={styles.button__checkbox}
          />
          <p className={styles.button__title}>
            Получать будет другой человек
          </p>
        </button>
        }

      </div>
      {isAnotherPerson &&
        <>
          {!showForm && <div className={styles.data}>
            {!!checkoutData.recipient.name &&
              <p className={styles.data__text}>
                {`${checkoutData.recipient.surname ?? ""} `}
                {`${checkoutData.recipient.name}`}
              </p>
            }

            {!!checkoutData.recipient.phone &&
              <p className={styles.data__text}>
                {`${checkoutData.recipient.phone}`}
              </p>
            }

            {!!checkoutData.recipient.email &&
              <p className={styles.data__text}>
                {checkoutData.recipient.email}
              </p>
            }

          </div>}

          {showForm &&
            <UserContantsFrom showForm={showForm} setShowForm={setShowForm} setAnotherPerson={setAnotherPerson} anotherPerson={anotherPerson} />
          }

          {!showForm &&
            <CustomButton
              className={styles["button-change"]}
              title="Изменить"
              onClick={() => setShowForm(true)}
            />
          }
        </>
      }

    </div>
  );
};

export default UserContacts;