import * as yup from "yup";

export const nameRegExp = /^[a-zA-Z, а-яёА-ЯЁ,.'-]+$/i;
// eslint-disable-next-line no-useless-escape
const phoneRegExp = /^\+?[7 ][-\(]?\d{3}\)?-?\d{3}-?\d{2}-?\d{2}$/;

export const userContactsSchema = yup.object().shape({
  id: yup.string(),
  name: yup.string()
    .matches(nameRegExp, "Некорректное имя пользователя")
    .test("not-only-spaces", "Имя не может состоять только из пробелов", value => {
      if (!value) return true;
      return value.trim().length > 0;
    }).required("Укажите, пожалуйста, ваше имя"),
  surname: yup.string()
    .matches(nameRegExp, "Некорректная фамилия пользователя")
    .test("not-only-spaces", "Фамилия не может состоять только из пробелов", value => {
      if (!value) return true;
      return value.trim().length > 0;
    }),
  phone: yup.string().matches(phoneRegExp, "Некорректный номер телефона").required("Номер телефона обязательное поле для заполнения"),
  email: yup.string().email("Некорректный формат email"),
});