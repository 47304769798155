import * as yup from "yup";
import { nameRegExp } from "./userContactsSchema";

// eslint-disable-next-line no-useless-escape
export const phoneRegExp = /^\+?[7 ][-\(]?\d{3}\)?-?\d{3}-?\d{2}-?\d{2}$/;

export const signUpSchema = yup.object().shape({
  name: yup
    .string()
    .matches(nameRegExp, "Некорректное имя пользователя")
    .required("Имя пользователя обязательное поле")
    .min(3, "Длина имени должна превышать 3 символов"),
  phoneNumber: yup
    .string()
    .required("Номер телефона обязательное поле")
    .matches(phoneRegExp, "Номер введён не правильно"),
  password: yup
    .string()
    .required("Пароль обязательное поле")
});
