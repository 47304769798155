import { useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";

import useShowShadow from "../../../../../../../utils/hooks/useShowShadow";

import CustomScrollbar from "../../../../../../../components/CustomScrollbar";
import ProductOffersListItem from "../../ProductOffersListItem";

import cn from "classnames";
import styles from "./styles.module.scss";

const OffersList = (props) => {
  const { showShadow, changeShowShadow, onScrollHandler } = useShowShadow();
  const [isShowScrollbar, setIsShowScrollbar] = useState(false);
  const scrollbarRef = useRef();

  useEffect(() => {
    if (!scrollbarRef.current) {
      return;
    }

    const scrollHeight = scrollbarRef.current.children[0].scrollHeight;
    const offsetHeight = scrollbarRef.current.offsetHeight;
    setIsShowScrollbar(scrollHeight > offsetHeight);
    changeShowShadow((prev) => {
      return {
        ...prev,
        bottom: scrollHeight > offsetHeight,
      };
    });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <CustomScrollbar
      scrollbarRef={scrollbarRef}
      scrollbarClassname={cn(
        styles["offer-picker"],
        props.offersScrollbarStyles,
        {
          [styles["offer-picker--top-shadow"]]: showShadow.top,
          [styles["offer-picker--bottom-shadow"]]: showShadow.bottom,
        }
      )}
      childrenClassName={cn(
        styles["offer-picker__list"],
        props.offersListStyles
      )}
      isShowScrollbar={isShowScrollbar}
      onScrollHandler={onScrollHandler}
    >
      {props.sortedOffers.map((offer) => {
        return (
          <ProductOffersListItem
            productId={props.productId}
            key={offer.id}
            offer={offer}
            isActiveOffer={props.activeOfferId === offer.id}
            onSelectOffer={props.onSelectOffer}
          />
        );
      })}
      {props.isLoading && !props.sortedOffers.length && (
        <p className={styles["offer--not-available"]}>Товара нет в наличии</p>
      )}

    </CustomScrollbar>
  );
};

const propTypes = {
  isLoading: PropTypes.bool,
  sortedOffers: PropTypes.array,
  activeOfferId: PropTypes.number,
  productId: PropTypes.number,
  onSelectOffer: PropTypes.func,
  offersScrollbarStyles: PropTypes.string,
  offersListStyles: PropTypes.string,
};

OffersList.propTypes = propTypes;

export default OffersList;
