import { useMemo, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";

import { removeAllItemsInCart, selectAllItemsInCart } from "../../../../../../store/user/user";
import { addProductToCart } from "../../../../../../store/user/cartThunks/cartThunks";

import Checkbox from "../../../../../../components/UI/Checkbox/Checkbox";

import styles from "./styles.module.scss";
import ModalAndBottomsheet from "../../../../../../components/ModalAndBottomsheet/ModalAndBottomsheet";
import CustomButton from "./../../../../../../components/CustomButton/CustomButton";
import cn from "classnames";
import { PropTypes } from "prop-types";
import { YandexActionTypeEnum } from "types/YandexActionTypeEnum";
import { handleYandexEcommerce } from "utils/yandexMetrics/yandexMetricsEcommerce";

const CartHeader = (props) => {
  const { cart } = useSelector((state) => state.user);
  const dispatch = useDispatch();

  const modalRef = useRef(null);

  const isAllSelected = useMemo(() => {
    if (!cart.length) {
      return false;
    }
    return cart.every((item) => {
      return item.isSelected;
    });
  }, [cart]);

  const isSomeSelected = useMemo(() => {
    return cart.some((item) => {
      return item.isSelected;
    });
  }, [cart]);

  // yandex ecommerce 'remove'
  const yandexEcommerceRemove = async (selectedItems) => {
    const selectedItemIds = new Set(selectedItems.map(item => item.id));
    const deletedItems = cart.filter(item => selectedItemIds.has(item.offer.id));

    if (!deletedItems || !deletedItems.length) {
      return;
    }

    const removeData = {
      ecommerce: {
        currencyCode: "RUB",
        [YandexActionTypeEnum.remove]: {
          products: deletedItems.map(deletedItem => {
            const isBrand = deletedItem.offer.catalog.brands && !!deletedItem.offer.catalog.brands.length;
            return {
              id: deletedItem.offer.catalog.id,
              name: deletedItem.offer.catalog.name,
              category: deletedItem.offer.catalog.category.name,
              brand: isBrand ? deletedItem.offer.catalog.brands[0].brand.name : "",
              price:  deletedItem.calculated_price,
              quantity: deletedItem.count,
              variant: `${deletedItem.offer.name}, ID: ${deletedItem.offer.id}`
            };
          })
        }
      }
    };

    await handleYandexEcommerce(removeData);
  };

  const selectedItems = useMemo(() => {
    return cart.reduce((acc, cur) => {
      if (cur.isSelected) {
        return acc + 1;
      }
      return acc;
    }, 0);
  }, [cart]);

  const selectAllProducts = () => {
    if (!cart.length) return;
    if (isAllSelected) return dispatch(removeAllItemsInCart());
    dispatch(selectAllItemsInCart());
  };

  const deleteSelectedProducts = async () => {
    props.setIsModal(false);

    try {
      const selected = cart.reduce((acc, cur) => {
        if (cur.isSelected) {
          acc.push({
            id: cur.offer.id,
            count: -1,
          });
        }
        return acc;
      }, []);

      await dispatch(addProductToCart(selected)).unwrap();

      // удаляем товары из данных яндекс коммерции (добавив удалённые)
      await yandexEcommerceRemove(selected);
    } catch (err) {
      // TODO
    }
  };

  const onDeleteProduct = async (id) => {
    try {
      const options = {
        id: id,
        count: -1,
      };
      dispatch(addProductToCart([options]));


      // удаляем товары из данных яндекс коммерции (добавив удалённые)
      await yandexEcommerceRemove([options]);
    } catch (err) {
      // TODO
    }
  };

  const deleteProductOrProdutcts = () => {
    if (props.idDelProduct) {
      onDeleteProduct(props.idDelProduct);
      closeModal();
      return;
    }
    deleteSelectedProducts();
  };

  const closeModal = () => {
    props.setIsModal(false);
    props.setIdDelProduct(null);
  };

  return (
    <>
      <div className={styles.root}>
        <div className={styles.content}>
          <Checkbox
            isSelected={isAllSelected}
            setIsSelected={selectAllProducts}
          />
          <button
            className={styles["content__select-button"]}
            onClick={selectAllProducts}
          >
            Выбрать всё
          </button>
          {isSomeSelected &&
            <button
              className={styles["content__delete-button"]}
              onClick={() => props.setIsModal(true)}
            >
              Удалить выбранное
            </button>
          }
        </div>
        <h3 className={styles.title}>
          Товары в корзине
        </h3>
      </div>
      <ModalAndBottomsheet
        onClose={closeModal}
        isOpen={props.isModal}
        modalRef={modalRef}
      >
        <div className={styles.modal}>
          {props.idDelProduct || selectedItems < 2 ? <h1 className={styles["modal_title"]}> Вы действительно хотите удалить выбранный товар?</h1> :
            <h1 className={styles["modal_title"]}> Вы действительно хотите удалить выбранные товары?</h1>
          }
          <div className={styles["modal_button-wrapper"]}>
            <CustomButton
              title="Да"
              isWhite
              onClick={deleteProductOrProdutcts}
              className={styles["modal_button-wrapper_button"]}
            />
            <CustomButton
              title="Нет"
              isBlack
              onClick={closeModal}
              className={cn(
                styles["modal_button-wrapper_button"],
                styles["modal_button-wrapper_button--primary"],
              )}
            />
          </div>
        </div>
      </ModalAndBottomsheet >
    </>
  );
};

const propTypes = {
  isModal: PropTypes.bool,
  setIsModal: PropTypes.func,
  setIdDelProduct: PropTypes.func,
  idDelProduct: PropTypes.number,
};

CartHeader.propTypes = propTypes;

export default CartHeader;