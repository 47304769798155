/* eslint-disable no-unused-vars */
import { PROVIDERS, WAY_TO_GET } from "./constants";

export const getAvailableProviders = (providersInfo) => {
  if (!providersInfo) {
    return [];
  }

  const toDoorProviders = providersInfo.toDoor?.map((item) => {
    return item.deliveryProvider;
  });

  const toPointProviders = providersInfo.toPoint?.map((item) => {
    return item.deliveryProvider;
  });

  const availableProviders = [
    ...new Set([...toDoorProviders, ...toPointProviders]),
  ];
  return availableProviders;
};

export const getProviderWithResultTitle = ({ providersInfo }) => {
  const providerTitle = PROVIDERS[providersInfo.deliveryProvider].title;
  const price =
    providersInfo.deliveryCost > 0
      ? `от ${providersInfo.deliveryCost}₽`
      : "- бесплатно";
  const daysAmount = `(${getDaysAmountString({
    daysMin: providersInfo.daysMin,
    daysMax: providersInfo.daysMax,
  })})`;

  return `${providerTitle} ${price} ${daysAmount}`;
};

export const getAvailableProvidersWithResultTitle = (data) => {
  if (!data) {
    return {
      toDoor: [],
      toPoint: [],
    };
  }
  return {
    toDoor: data.toDoor?.map((item) => {
      return {
        ...item,
        resultTitle: getProviderWithResultTitle({
          providersInfo: item,
          wayToGet: WAY_TO_GET.toDoor,
        }),
      };
    }),
    toPoint: data.toPoint?.map((item) => {
      return {
        ...item,
        resultTitle: getProviderWithResultTitle({
          providersInfo: item,
          wayToGet: WAY_TO_GET.toPoint,
        }),
      };
    }),
  };
};

function getDaysAmountString({ daysMin, daysMax }) {
  const correctDayForm = getCorrectDayForm(daysMax);
  return `${daysMin} - ${daysMax} ${correctDayForm}`;
}

function getCorrectDayForm(number) {
  const titles = ["день", "дня", "дней"];
  const cases = [2, 0, 1, 1, 1, 2];
  const condition = number % 100 > 4 && number % 100 < 20;

  const currentIdx = condition ? 2 : cases[number % 10 < 5 ? number % 10 : 5];
  return `${titles[currentIdx]}`;
}
