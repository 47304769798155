import { nanoid } from "@reduxjs/toolkit";
import { toast } from "react-toastify";

import { deliveryApi } from "../api/deliveryApi";

import { PROVIDERS } from "./constants";
import { getBalloon } from "./helpers/yMaps/yMapsObjectsGenerator";
import { getCityData } from "../api/geocodeApi";
import { AddAvailableProvidersOnMapParams } from "./hooks/useYandexMap";
import { Feature, FeatureCollection, GetPlacemarkOnMapParams, IEvent, IMap, Item, IUserPlacemark, ObjectManager } from "types/IYMapTypes";
import { SelectedProviderData } from "types/IProviderInfo";
import { Coordinates } from "types/ICheckoutData";

declare global {
  interface Window {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    ymaps: any;
  }
}

export interface IAddProviderCollectionToMap {
  map: IMap;
  collection: FeatureCollection;
  objectManager: ObjectManager;
}

export interface IChangePlacemartCoordinates {
  map: IMap;
  coordinates: Coordinates;
  userPlacemark: IUserPlacemark;
}

// type City = {
//   city: string
// }

export const INIT_COORDS: Coordinates = [45.03529843993246, 38.9769731798558]; // Krasnodar;
export const OFFSET = 0;
export const SCROLL_DURATION = 1000;
const PRIMARY_COLOR = "#C73C87";

export const createYMapsScript = () => {
  const script = document.createElement("script");
  script.src = `https://api-maps.yandex.ru/2.1/?apikey=${process.env.REACT_APP_YANDEX_MAPS_API_KEY}&lang=ru_RU`;
  document.head.appendChild(script);
};

export const getCityCoordinates = async (cityName: string): Promise<Coordinates> => {
  try {
    const response = await getCityData(cityName);
    if(!response) {
      throw new Error("Не получен ответ на получение координат");
    }
    const coordinates =  response.response.GeoObjectCollection.featureMember[0].GeoObject.Point.pos.split(" ").reverse().map(parseFloat);
    if (!coordinates || coordinates.length !== 2) {
      throw new Error("Координаты в getCityCoordinates undefined или некорректны");
    }
    return coordinates;
  } catch (error) {
    // TODO
    // eslint-disable-next-line no-console
    console.error("Ошибка в  getCityCoordinates:", error);
    toast(`Не удалось получить координаты выбранного города ${cityName}`);
    return INIT_COORDS;
  }
};

export const setMapCenter = ({ map, coordinates }: { map: IMap; coordinates: Coordinates }) => {
  let pixelCenter = map.getGlobalPixelCenter(coordinates);

  pixelCenter = [pixelCenter[0] - OFFSET, pixelCenter[1]];
  const geoCenter = map.options
    .get("projection")
    .fromGlobalPixels(pixelCenter, map.getZoom());
  map.setCenter(geoCenter);
};

export const getPlacemarkOnMap = ({ map, coordinates, cb, isDraggable } : GetPlacemarkOnMapParams) => {
  const placemark = new window.ymaps.Placemark(
    coordinates,
    {},
    { draggable: isDraggable, iconColor: PRIMARY_COLOR }
  );

  placemark.events.add("dragend", function (e: IEvent) {
    const coords = e.get("target").geometry.getCoordinates();
    cb?.(coords);
  });
  map.geoObjects.add(placemark);
  return placemark;
};

export const getUpdateCoordinatesByOffset = ({ map, coordinates }: { map: IMap; coordinates: Coordinates }): Coordinates => {
  const pixelOffset = map.options
    .get("projection")
    .toGlobalPixels(coordinates, map.getZoom());
  const updatedPixelOffset = [pixelOffset[0] - OFFSET, pixelOffset[1]];
  const updatedCoordinates = map.options
    .get("projection")
    .fromGlobalPixels(updatedPixelOffset, map.getZoom());
  return updatedCoordinates;
};

export const scrollToPlacemark = async ({ map, coordinates }: { map: IMap; coordinates: Coordinates }): Promise<void> => {
  try {
    await map.panTo(coordinates, {
      flying: false,
      duration: 1200,
    });
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error("Произошла ошибка при перемещении карты:", error);
    throw error;
  }
};

export const getCityBySearchAddress = async (address: string): Promise<string[]> => {
  try {
    const result = await window.ymaps.geocode(address);
    const firstGeoObject = result.geoObjects.get(0);

    if (!firstGeoObject) {
      throw new Error("Не найден объект по запросу");
    }

    const city = firstGeoObject.getLocalities().length
      ? firstGeoObject.getLocalities()
      : firstGeoObject.getAdministrativeAreas();
    return city;
  } catch (err) {
    // eslint-disable-next-line no-console
    console.error("Произошла ошибка при поиске города:", err);
    throw err;
  }
};

export const getProviderCollection = (items: Item[]): FeatureCollection => {
  const features: Feature[] = [];

  for (let i = 0; i < items.length; i++) {
    const params = {
      address: items[i].address,
      deliveryProvider: PROVIDERS[items[i].deliveryProvider].title,
      deliveryProviderType: items[i].deliveryProvider,
      description: items[i].description,
      paymentCard: items[i].paymentCard,
      paymentCash: items[i].paymentCash,
      phone: items[i].phone,
      timeTable: items[i].timeTable,
      mySecretCustomId: nanoid(),
    };

    const { header, body, footer } = getBalloon(params);
    features.push({
      type: "Feature",
      id: nanoid(),
      geometry: {
        type: "Point",
        coordinates: [items[i].lat, items[i].lng],
        providerType: PROVIDERS[items[i].deliveryProvider].type,
        params,
      },
      properties: {
        balloonContentHeader: header,
        balloonContentBody: body,
        balloonContentFooter: footer,
        hintContent: `${PROVIDERS[items[i].deliveryProvider].title}`,
      },
      options: {
        iconLayout: "default#image",
        iconImageSize: [34, 41]
      }
    });
  }

  return {
    type: "FeatureCollection",
    features,
  };
};

export const addProviderCollectionToMap = ({
  map,
  collection,
  objectManager,
}: IAddProviderCollectionToMap) => {
  objectManager.add(collection);
  map.geoObjects.add(objectManager);
};

export const changePlacemartCoordinates = ({
  map,
  coordinates,
  userPlacemark,
}: IChangePlacemartCoordinates): void => {
  try {
    userPlacemark.geometry.setCoordinates(coordinates);
    const updatedCoordinates: Coordinates = getUpdateCoordinatesByOffset({
      map,
      coordinates,
    });

    scrollToPlacemark({ map, coordinates: updatedCoordinates })
      .then(() => { return; })
      .catch((error) => {
        // eslint-disable-next-line no-console
        console.error("Ошибка при перемещении карты:", error);
      });
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error("Ошибка при изменении координат:", error);
  }
};

export const addAvailableProvidersOnMap = ({
  city,
  availableProviders,
  setAvailableProviders,
  setFeatures
}: AddAvailableProvidersOnMapParams): void => {
  availableProviders.forEach(async (provider: string) => {

    const data = await deliveryApi.getProvidersList({
      city,
      availableOperations: [2, 3],
      providers: [provider],
    });

    if ("message" in data) {
      return toast(data.message);
    }
    const providerResponse = Object.entries(data.response);


    if (Array.isArray(providerResponse[0][1])) {
      const collection = getProviderCollection(providerResponse[0][1]);
      setFeatures((prev: Feature[]) => [...prev, ...collection.features]);
    }

    const providerData = {
      type: provider,
      addresses: providerResponse[0][1] as SelectedProviderData[],
      selected: true,
    };

    setAvailableProviders?.(providerData);
  });
};