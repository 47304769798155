import PropTypes from "prop-types";

import Portal from "../../../../HOC/Portal";
import CustomLink from "../../../../components/CustomLink";

import styles from "./styles.module.scss";

const PromocodeModal = (props) => {
  return(
    <Portal modalRef={props.modalRef} onClose={props.onClose}>
      <div className={styles.root}>
        <div
          className={styles.modal}
          ref={props.modalRef}
        >
          <h3 className={styles.title}>
          Промокоды могут использовать только авторизованные пользователи.
          </h3>
          <div className={styles["buttons-wrapper"]}>
            <CustomLink
              title="Авторизоваться"
              path='/sign-in'
              className={styles.button}
            />
            <CustomLink
              title="Зарегистрироваться"
              path='/sign-up'
              className={styles.button}
            />
          </div>
        </div>
        <div className={styles.background} />
      </div>
    </Portal>
  );
};

const propTypes = {
  isOpen: PropTypes.bool,
  modalRef: PropTypes.object.isRequired,
  onClose: PropTypes.func.isRequired,
};

PromocodeModal.propTypes = propTypes;

export default PromocodeModal;