import { useDispatch, useSelector } from "react-redux";
import PropTypes from "prop-types";

import { addProductToCart } from "../../../store/user/cartThunks/cartThunks";
import getSortedOffersByPriceAndVolume from "../../../utils/getSortedOffersByPriceAndVolume";

import Portal from "../../../HOC/Portal";
import CustomButton from "../../CustomButton";
import CustomLink from "../../CustomLink";
import OffersList from "../../../pages/SingleProductPage/Components/ProductMainContent/components/ProductOffers/OffersList";
import { useMemo, useState } from "react";
import { toast } from "react-toastify";
import { ReactComponent as CrossIcon } from "../../../assets/icons/cross-icon.svg";
import { metric } from "../../../utils/yandexMetrics/yandexMetrics";
import { YandexActionTypeEnum } from "types/YandexActionTypeEnum";

import styles from "./styles.module.scss";
import { handleYandexEcommerce } from "utils/yandexMetrics/yandexMetricsEcommerce";

const ProductCardOffersModal = props => {
  const { cart } = useSelector((state) => state.user);
  const dispatch = useDispatch();

  const sortedOffers = useMemo(() => {
    if (!props.offers) return [];
    const filteredOffers = props.offers.filter((e) => e.available);
    return getSortedOffersByPriceAndVolume(filteredOffers);
  }, [props.offers]);

  const [currentOffer, setCurrentOffer] = useState({
    id: sortedOffers[0]?.id ?? 0,
    description: sortedOffers[0]?.name ?? "",
    promotionPrice:
      sortedOffers[0]?.price_sale ?? sortedOffers[0]?.akcia ?? null,
    price: sortedOffers[0]?.price ?? null,
    percent: sortedOffers[0]?.percent ?? null,
  });

  const onSelectOffer = options => {
    setCurrentOffer(options);
  };

  const wordsArr = props.title.split(" ");
  const fiveWords = wordsArr.splice(0, 5);
  const text = fiveWords.lenght <= wordsArr.length ? fiveWords.join(" ") : fiveWords.join(" ") + "...";

  // yandex ecommerce 'add'
  const yandexEcommerceAdd = async (id) => {
    const chosenOffer = props.productItem.offers.find(item => item.id === id);

    if (!chosenOffer) {
      return;
    }
    const productVariantString = `${chosenOffer.name}, ID: ${chosenOffer.id}`;
    const ecommerceAddData = {
      ecommerce: {
        currencyCode: "RUB",
        [YandexActionTypeEnum.add]: {
          products: [
            {
              id: props.productItem.id,
              name: props.productItem.name,
              category: props.productItem.category.name,
              brand: (props.productItem.brands && !!props.productItem.brands.length) ? props.productItem.brands[0].brand.name : "",
              price: currentOffer.promotionPrice ?? currentOffer.price,
              quantity: 1,
              variant: productVariantString
            }
          ]
        }
      }
    };

    await handleYandexEcommerce(ecommerceAddData);
  };

  const onAddProductToCart = async e => {
    e.stopPropagation();
    if (process.env.REACT_APP_REDIRECT_CART === "true") {
      props.onClose();
      return props.setIsOpenRedirectModalCart(true);
    }

    try {
      const options = {
        id: currentOffer.id,
        count: 1,
      };
      dispatch(addProductToCart([options]));
      toast(`Товар "${text}" добавлен в корзину!`);
      metric.addToBasketMetric(currentOffer.promotionPrice, "RUB");

      // yandex ecommerce 'add'
      await yandexEcommerceAdd(options.id);
    } catch (err) {
      // eslint-disable-next-line no-console
      console.error("При добавлении товара в корзину произошла ошибка", err);
    }
  };

  const handlerStopPropagation = e => {
    e.stopPropagation();
  };

  const productInCart = useMemo(() => cart.find(e => e.offer.id === currentOffer.id), [cart, currentOffer]);

  const addProductAmount = async () => {
    try {
      const options = {
        id: currentOffer.id,
        count: productInCart.count + 1,
      };
      dispatch(addProductToCart([options]));
      toast(`Товар "${text}" добавлен в корзину!`);
      metric.addToBasketMetric(currentOffer.promotionPrice, "RUB");

      // yandex ecommerce 'add'
      await yandexEcommerceAdd(options.id);
    } catch (err) {
      // eslint-disable-next-line no-console
      console.error("Произошла ошибка при добавлении товара в корзину", err);
    }
  };

  return (
    <>
      <Portal modalRef={props.modalRef} onClose={props.onClose}>
        <section
          className={styles.root}
          ref={props.modalRef}
          onClick={handlerStopPropagation}
        >
          <button className={styles["close-button"]} onClick={props.onClose}>
            <CrossIcon />
          </button>
          <div className={styles["root_img-wrapper"]}>
            <img
              src={props.logoSrc}
              alt={"TODO"}
              className={styles["root_img-wrapper-img"]}
            />
          </div>
          <div className={styles["content-wrapper"]}>
            <div className={styles.info}>
              <h4 className={styles.description}>{props.productDescription}</h4>
              <p className={styles.title}>{props.title}</p>
              <OffersList
                sortedOffers={sortedOffers}
                activeOfferId={currentOffer.id}
                offersScrollbarStyles={styles["offers__list-wrapper"]}
                offersListStyles={styles.offers__list}
                onSelectOffer={onSelectOffer}
              />
            </div>
            <div className={styles.buttons}>
              <CustomLink
                title="Подробнее о товаре"
                path={props.productUrl}
                isPrimary
              />
              <CustomButton
                title={productInCart ? "Добавить ещё" : "Добавить в корзину"}
                onClick={!productInCart ? onAddProductToCart : addProductAmount}
                className={styles.buttons__add}
              />
            </div>
          </div>
        </section>
        <div className={styles.background} />
      </Portal>
    </>
  );
};

const propTypes = {
  logoSrc: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  productDescription: PropTypes.string.isRequired,
  offers: PropTypes.array.isRequired,
  productUrl: PropTypes.string.isRequired,
  modalRef: PropTypes.object.isRequired,
  onClose: PropTypes.func.isRequired,
  setIsOpenRedirectModalCart: PropTypes.func,
  productItem: PropTypes.object.isRequired
};

ProductCardOffersModal.propTypes = propTypes;

export default ProductCardOffersModal;
