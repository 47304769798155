import { Helmet } from "react-helmet";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";

import { getUserToken, getValueFromStorage } from "../../../utils/localStorage";

import { setCheckoutItems } from "../../../store/user/user";

import SectionTitle from "../../../components/SectionTitle";
import UserContacts from "./components/UserContacts";
import UserOrderData from "./components/UserOrderData";
import WayToGet from "./components/WayToGet/WayToGet";
import PayOrder from "./components/PayOrder/PayOrder";

import styles from "./styles.module.scss";

const Checkout = () => {
  const { cart, checkoutData } = useSelector((state) => state.user);
  const [isPay, setIsPay] = useState(false);
  const [orderData, setOrderData] = useState({});
  const [isAuthorized, setIsAuthorized] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    const token = getUserToken();
    if (!token) {
      return;
    }
    setIsAuthorized(true);
  }, []);

  useEffect(() => {
    if (checkoutData.items.length > 0) {
      return;
    }
    const items = getValueFromStorage("checkoutItemsIds");
    dispatch(setCheckoutItems(JSON.parse(items)));
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cart]);

  return (
    <>
      <Helmet>
        <meta name="robots" content="noindex" />
      </Helmet>
      <main className={styles.root}>
        <div className={styles["title-wrapper"]}>
          <Link to="/cart" className={styles.link}>
            {"< вернуться в корзину"}
          </Link>
          <SectionTitle title="Оформление заказа" />
        </div>
        {isPay ? (
          <PayOrder orderData={orderData} />
        ) : (
          <section className={styles.data}>
            <div>
              <UserContacts />
              <WayToGet />
            </div>
            <UserOrderData
              setIsPay={setIsPay}
              setOrderData={setOrderData}
              isAuthorized={isAuthorized}
            />
          </section>
        )}
      </main>
    </>
  );
};

export default Checkout;