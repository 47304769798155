import { combineReducers } from "@reduxjs/toolkit";
import user from "./user/user";
import catalog from "./catalog";
import catalogV3 from "./catalogV3";

const rootReducer = combineReducers({
  user,
  catalog,
  catalogV3
});

export default rootReducer;