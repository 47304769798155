import { useSelector } from "react-redux";
import { useState, useEffect, useMemo } from "react";

import { productsApi } from "../../../../api/productsApi";

import CustomLink from "../../../../components/CustomLink";
import SectionTitle from "../../../../components/SectionTitle";
import MainSlider from "../../../../components/MainSlider";
import ProductCard from "../../../../components/ProductCard";
import { getErrorData } from "utils/getErrorData";

import cn from "classnames";
import styles from "./styles.module.scss";

export const SELECT_TYPE_TABS = [
  {
    id: "1",
    title: "Парфюмерия",
    values: {
      categories: "parfumeria",
    },
  },
  {
    id: "2",
    title: "Для волос",
    values: {
      categories: "kosmetika",
      "properties[kategoria]": "dla-volos",
    },
  },
  {
    id: "3",
    title: "Для тела",
    values: {
      categories: "kosmetika",
      "properties[kategoria]": "dla-tela",
    },
  },
  {
    id: "4",
    title: "Для лица",
    values: {
      categories: "kosmetika",
      "properties[kategoria]": "dla-lica",
    },
  },
];

const PromosAndSalesBlock = () => {
  const { deviceType } = useSelector((state) => state.user);

  const [products, setProducts] = useState([]);
  const [activeTabId, setActiveTabId] = useState(SELECT_TYPE_TABS[0].id);
  const [isOffersModalOpened, setIsOffersModalOpened] = useState(false);

  const slidesToScroll = useMemo(() => {
    if (deviceType.isMobile) {
      return 2;
    }
    return deviceType.isDesktop || deviceType.isLargeDesktop ? 4 : 3;
  }, [deviceType]);

  useEffect(() => {
    (async () => {
      try {
        const currentTab = SELECT_TYPE_TABS.find((item) => item.id === activeTabId);

        const data = await productsApi.getProductsWithLimit({
          limit: 16,
          badges: "akcia,sale",
          ...currentTab.values,
        });

        if (!data?.response?.items) {
          const { message } = getErrorData(data);
          throw new Error(message);
        }

        setProducts(data.response.items ?? []);
      } catch (err) {
        // eslint-disable-next-line no-console
        console.error("Ошибка при получении товаров в разделе Скидки: -->", err?.message);
      }
    })();
  }, [activeTabId]);

  const onClickSelectTab = (id) => {
    setActiveTabId(id);

  };

  const onToggleIsOffersModalOpenedStatus = (value) => {
    setIsOffersModalOpened(value);
  };

  return (
    <section className={styles.root}>
      <SectionTitle title={!deviceType.isMobile ? "Скидки: наши акции и распродажи" : "Скидки"} />
      <div className={styles["slider-wrapper"]}>
        {!!products.length && (
          <MainSlider
            onClickSelectTab={onClickSelectTab}
            setActiveTabId={setActiveTabId}
            activeTabId={activeTabId}
            slidesSpacing={!deviceType.isMobile ? 150 : undefined}
            sliderContainerStyles={styles.slider}
            contentStyles={styles.content}
            paginationStyles={styles.pagination}
            dotsStyles={styles.dots}
            shouldAutoSwitched={!isOffersModalOpened}
            shouldBeLooped
            shouldControlAutoSwitched
          >
            {Array.from({ length: Math.ceil(products.length / slidesToScroll) }, (_, i) => (
              <div className={cn(styles["grouped-cards"], "keen-slider__slide")} key={`row${i}`}>
                {products.slice(i * slidesToScroll, (i + 1) * slidesToScroll).map((product) => {
                  return (
                    <div key={product.id} className={styles["card-wrapper"]}>
                      <ProductCard
                        product={product}
                        key={product.id}
                        onToggleIsOffersModalOpenedStatus={onToggleIsOffersModalOpenedStatus}
                      />
                    </div>
                  );
                })}
              </div>
            ))}
          </MainSlider>
        )}
      </div>
      <CustomLink
        path="/catalog?badges=akcia,sale"
        title="Смотреть все"
        className={styles["see-more"]}
      />
    </section>
  );
};

export default PromosAndSalesBlock;
