import { toast } from "react-toastify";
import { getUserToken, removeTokens } from "../utils/localStorage";
import { CustomError, getErrorData, IErrorData, IGenericResponseRoot } from "utils/getErrorData";
import { IRequestSignIn, IResponseSignIn } from "types/ISignIn";
import { IResponseRefreshUser } from "types/IRefreshUser";
import { IRequestSignUp, IResponseSignUp } from "types/ISingUp";
import { IRequestUpdateUser } from "types/IUpdateUser";
import { IDeliveryAdress, IDeliveryAdressAddRequest } from "types/IDeliveryAdressAction";
import { IChangeUserPasswordRequest } from "types/IChangeUserPassword";
import { IFavoriteProducts, IFavoriteProductsForDelete } from "types/IFavoriteProductsActions";
import { IGetUserOrderDataRequest, IGetUserOrderDataResponse } from "types/IGetUserOrderData";
import { IGetUserCartRequest, IGetUserCartResponse } from "types/IGetUserCart";
import { IRequestAddToCartAuthorized, IResponseAddToCartAuthorize } from "types/IAddProductToCart";
import { IRequestAddToCartPublic, IResponseAddToCartPublic } from "types/IAddProductToCartPublic";
import { ICreateNewOrderNonAuthorizedUsersRequest, ICreateNewOrderNonAuthorizedUsersResponse } from "types/ICreateNewOrderNonAuthorizedUsers";
import { ICreateNewOrderAuthorizedUsersRequest, ICreateNewOrderAuthorizedUsersResponse } from "types/ICreateNewOrderAuthorizedUsers";
import { IRestoreUserPasswordRequest } from "types/IRestoreUserPassword";
import { objectEntries } from "utils/helpers/typeGuardObjEntries";
import { IGetOrderPaymentLinkRequest, IOrderPaymentLinkResponse } from "types/IGetOrderPaymentLink";
import { YANDEX_METRICS_CLIENT_ID_KEY } from "utils/yandexMetrics/yandexMetricsCore";
import { IUser } from "types/IUser";


const checkUser = async (): Promise<IGenericResponseRoot<IUser> | IErrorData> => {
  const token = getUserToken();
  try {
    const res = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/private/account`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    const data = await res.json();

    if (!res.ok) {
      const errorData = getErrorData(data);
      return errorData;
    }

    return data as IGenericResponseRoot<IUser>;
  } catch (error) {
    const errorData  = getErrorData(error);
    return errorData;
  }
};

const signIn = async (user: IRequestSignIn): Promise<IGenericResponseRoot<IResponseSignIn> | IErrorData> => {
  const clientID = localStorage.getItem(YANDEX_METRICS_CLIENT_ID_KEY);
  const formData = new FormData();

  formData.append("username", user.username);
  formData.append("password", user.password);

  try {
    const fetchParams = {
      method: "POST",
      body: formData,
      headers:{}
    };

    const allowClientIdInHeaders = process.env.REACT_APP_CLIENT_ID_ALLOW === "true";

    if (allowClientIdInHeaders && clientID && !!clientID.length) {
      fetchParams.headers = {
        ...fetchParams.headers,
        [YANDEX_METRICS_CLIENT_ID_KEY]: clientID
      };
    }

    const res = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/public/login`, fetchParams);

    if (res.status === 500) {
      throw new Error("Произошла необработанная ошибка при авторизации.");
      // TODO: Обработать отправку ошибок на бэк в теории
    }

    const data = await res.json();

    if (!res.ok) {
      const errorData = getErrorData(data);
      return errorData;
    }

    return data as IGenericResponseRoot<IResponseSignIn>;
  } catch (error) {
    const errorData  = getErrorData(error);
    return errorData;
  }
};

const refreshUser = async (): Promise<IGenericResponseRoot<IResponseRefreshUser> | IErrorData> => {
  const formData = new FormData();

  const accessToken: string | null = localStorage.getItem("accessToken");
  const refreshToken: string | null = localStorage.getItem("refreshToken");
  const userKey: string | null = localStorage.getItem("userKey");

  formData.append("token", accessToken);
  formData.append("refresh_token", refreshToken);
  formData.append("refresh_key", userKey);

  try {

    const res = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/public/login/refresh`, {
      method: "POST",
      body: formData,
    });

    const data = await res.clone().json();

    if (!res.ok) {
      const { message } = getErrorData(data);
      throw new Error(message);
    }

    return data as IGenericResponseRoot<IResponseRefreshUser>;
  } catch (error) {
    // обнуляем поля токена ключа и рефреш токена при ошибке
    removeTokens();
    toast.info("Авторизация истекла, авторизуйтесь повторно");
    const errorData  = getErrorData(error);
    return errorData;
  }
};

const signUp = async (user: IRequestSignUp): Promise<IGenericResponseRoot<IResponseSignUp> | IErrorData> => {
  const clientID = localStorage.getItem(YANDEX_METRICS_CLIENT_ID_KEY);
  const formData = new FormData();

  formData.append("phone", user.phone);
  formData.append("name", user.name);
  formData.append("password", user.password);

  try {
    const fetchParams = {
      method: "POST",
      body: formData,
      headers: {}
    };

    const allowClientIdInHeaders = process.env.REACT_APP_CLIENT_ID_ALLOW === "true";

    if (allowClientIdInHeaders && clientID && !!clientID.length) {
      fetchParams.headers = {
        ...fetchParams.headers,
        [YANDEX_METRICS_CLIENT_ID_KEY]: clientID
      };
    }

    const res = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/public/account/register`, fetchParams);

    if (res.status === 500) {
      throw new Error("Произошла необработанная ошибка при регистрации.");
      // TODO: Обработать отправку ошибок на бэк в теории
    }

    const data = await res.json();

    if (!res.ok) {
      const { message, code } = getErrorData(data);
      throw new CustomError(message, code);
    }

    return data as IGenericResponseRoot<IResponseSignUp>;
  } catch (error) {
    const errorData  = getErrorData(error);
    return errorData;
  }

};

const updateUser = async (user:IRequestUpdateUser): Promise<IGenericResponseRoot<IUser> | IErrorData> => {
  const data: Partial<IRequestUpdateUser> = {};
  const token = getUserToken();

  // eslint-disable-next-line prefer-const
  for (let key in user) {
    if (user[key as keyof IRequestUpdateUser]) {
      data[key as keyof IRequestUpdateUser] = user[key as keyof IRequestUpdateUser];
    }
  }
  try {
    const res = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/private/account/update`, {
      method: "POST",
      body: JSON.stringify(data),
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    if (res.status === 500) {
      throw new Error("Произошла необработанная ошибка при обновлении данных пользователя.");
      // TODO: Обработать отправку ошибок на бэк в теории
    }

    const response =  await res.json();

    if (!res.ok) {
      const { message } = getErrorData(data);
      throw new Error(message);
    }
    return response as IGenericResponseRoot<IUser>;
  } catch (error) {
    const errorData  = getErrorData(error);
    return errorData;
  }
};


const addDeliveryAdress = async (user: IDeliveryAdressAddRequest): Promise<IGenericResponseRoot<IDeliveryAdress[]> | IErrorData> => {
  const data: Partial<IDeliveryAdressAddRequest> = {};
  const token = getUserToken();

  for (const [key, value] of objectEntries(user)) {
    if (value !== undefined && value !== null) {
      data[key] = value;
    }
  }

  try {
    const res = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/private/account/delivery-address`, {
      method: "POST",
      body: JSON.stringify(data),
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    const response =  await res.json();

    if (!res.ok) {
      const { message } = getErrorData(response);
      throw new Error(message);
    }
    return response as IGenericResponseRoot<IDeliveryAdress[]>;

  } catch (error) {
    const errorData  = getErrorData(error);
    return errorData;
  }
};

const removeDeliveryAdress = async (addressId: number): Promise<IGenericResponseRoot<IDeliveryAdress[]> | IErrorData> => {
  const token = getUserToken();
  try {
    const res = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/private/account/delivery-address/${addressId}`, {
      method: "DELETE",
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    const response =  await res.json();
    if (!res.ok) {
      const { message } = getErrorData(response);
      throw new Error(message);
    }
    return response as IGenericResponseRoot<IDeliveryAdress[]>;
  } catch (error) {
    const errorData  = getErrorData(error);
    return errorData;
  }
};

const changeUserPassword = async (data: IChangeUserPasswordRequest): Promise<IGenericResponseRoot<string | null> | IErrorData> => {
  const token = getUserToken();
  try {
    const res = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/private/account/change-password`, {
      method: "POST",
      body: JSON.stringify(data),
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    if (res.status === 500) {
      throw new Error("Произошла необработанная ошибка при смене пароля.");
      // TODO: Обработать отправку ошибок на бэк в теории
    }

    const responseData: IGenericResponseRoot<null> = await res.json();

    if (!res.ok) {
      const { message } = getErrorData(responseData);
      throw new Error(message);
    }

    return responseData;

  } catch (error) {
    const errorData  = getErrorData(error);
    return errorData;
  }
};

const getFavoriteProducts = async (): Promise<IGenericResponseRoot<IFavoriteProducts> | IErrorData> => {
  const token = getUserToken();
  try {
    const res = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/private/favorite-products`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    const response =  await res.json();
    if (!res.ok) {
      const { message } = getErrorData(response);
      throw new Error(message);
    }
    return response as IGenericResponseRoot<IFavoriteProducts>;

  } catch (error) {
    const errorData  = getErrorData(error);
    return errorData;
  }
};

const addFavoriteProduct = async (id: number): Promise<IGenericResponseRoot<IFavoriteProducts> | IErrorData> => {
  const token = getUserToken();
  try {
    const res = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/private/favorite-product`, {
      method: "POST",
      body: JSON.stringify({ product: id }),
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    const response =  await res.json();
    if (!res.ok) {
      const { message } = getErrorData(response);
      throw new Error(message);
    }
    return response as IGenericResponseRoot<IFavoriteProducts>;

  } catch (error) {
    const errorData  = getErrorData(error);
    return errorData;
  }
};

const removeFavoriteProduct = async (id: number): Promise<IGenericResponseRoot<IFavoriteProductsForDelete> | IErrorData> => {
  const token = getUserToken();
  try {
    const res = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/private/favorite-product`, {
      method: "DELETE",
      body: JSON.stringify({ product: id }),
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    const response =  await res.json();
    if (!res.ok) {
      const { message } = getErrorData(response);
      throw new Error(message);
    }

    return response as IGenericResponseRoot<IFavoriteProductsForDelete>;
  } catch (error) {
    const errorData  = getErrorData(error);
    return errorData;
  }
};

const getUserOrders = async (options: IGetUserOrderDataRequest): Promise<IGenericResponseRoot<IGetUserOrderDataResponse>> => {
  const requestData = objectEntries(options).map(([key, value], idx) => {
    if (typeof value === "undefined") {
      return "";
    }
    if (idx === 0) {
      return `?${key}=${value}`;
    }
    return `&${key}=${value}`;
  });

  const token = getUserToken();

  const res = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/private/orders${requestData.join("")}`, {
    method: "GET",
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

  return res.json();
};

const getUserCart = async (options: IGetUserCartRequest): Promise<IGenericResponseRoot<IGetUserCartResponse>> => {

  const requestData = objectEntries(options).map(([key, value], idx) => {
    if (typeof value === "undefined") {
      return "";
    }
    if (idx === 0) {
      return `?${key}=${value}`;
    }
    return `&${key}=${value}`;
  });

  const token = getUserToken();

  const res = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/private/basket${requestData.join("")}`, {
    method: "GET",
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

  return res.json();
};

const addProductToCart = async (items: IRequestAddToCartAuthorized): Promise<IGenericResponseRoot<IResponseAddToCartAuthorize>> => {
  const token = getUserToken();

  const res = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/private/basket`, {
    method: "POST",
    body: JSON.stringify({ offers: items }),
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

  return res.json();
};

const addProductToCartPublic = async (items: IRequestAddToCartPublic): Promise<IGenericResponseRoot<IResponseAddToCartPublic>> => {
  const res = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/public/basket`, {
    method: "POST",
    body: JSON.stringify({ offers: items }),
  });

  return res.json();
};

const addNewOrderForAuthorizedUsers = async (options: ICreateNewOrderAuthorizedUsersRequest): Promise<IGenericResponseRoot<ICreateNewOrderAuthorizedUsersResponse>> => {
  const clientID = localStorage.getItem(YANDEX_METRICS_CLIENT_ID_KEY);
  const token = getUserToken();

  const fetchParams = {
    headers: {
      Authorization: `Bearer ${token}`,
    } as HeadersInit,
    method: "POST",
    body: JSON.stringify(options),
  };

  const allowClientIdInHeaders = process.env.REACT_APP_CLIENT_ID_ALLOW === "true";

  if (allowClientIdInHeaders && clientID && !!clientID.length) {
    fetchParams.headers = {
      ...fetchParams.headers,
      [YANDEX_METRICS_CLIENT_ID_KEY]: clientID
    };
  }

  const res = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/private/order`, fetchParams);

  return res.json();
};

const addNewOrderForNonAuthorizedUsers = async (options: ICreateNewOrderNonAuthorizedUsersRequest): Promise<IGenericResponseRoot<ICreateNewOrderNonAuthorizedUsersResponse>> => {
  const clientID = localStorage.getItem(YANDEX_METRICS_CLIENT_ID_KEY);
  const fetchParams = {
    headers: {
      "Content-Type": "application/json",
    } as HeadersInit,
    method: "POST",
    body: JSON.stringify(options),
  };

  const allowClientIdInHeaders = process.env.REACT_APP_CLIENT_ID_ALLOW === "true";

  if (allowClientIdInHeaders && clientID && !!clientID.length) {
    fetchParams.headers = {
      ...fetchParams.headers,
      [YANDEX_METRICS_CLIENT_ID_KEY]: clientID
    };
  }
  const res = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/public/order`, fetchParams);

  return res.json();
};

const restoreUserPassword = async (options: IRestoreUserPasswordRequest): Promise<IGenericResponseRoot<string> | IErrorData> => {
  try {
    if (options.method !== "phone" && options.method !== "email") {
      throw new Error("Метод восстановления пароля указан неверно");
    }

    const bodyData = {
      method: options.method,
      contact: options.contact,
      code: options.code,
      "new_password": options.new_password
    };

    const res = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/public/account/password/restore`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json"
      },
      body: JSON.stringify(bodyData),
    });

    if (res.status === 500) {
      throw new Error("Произошла необработанная ошибка при восстановлении пароля.");
      // TODO: Обработать отправку ошибок на бэк в теории
    }

    const response = await res.json();

    if (!res.ok) {
      const errorData = getErrorData(response);
      throw new CustomError(errorData.message, errorData.code);
    }

    return response as IGenericResponseRoot<string>;
  } catch (err) {
    return getErrorData(err);
  }
};

const getOrderPaymentLink = async (options: IGetOrderPaymentLinkRequest): Promise<IGenericResponseRoot<IOrderPaymentLinkResponse>> => {
  const token = getUserToken();
  const url = token ? `${process.env.REACT_APP_BACKEND_URL}/api/private/payment` : `${process.env.REACT_APP_BACKEND_URL}/api/public/payment`;

  const headers: Record<string, string> = {
    "Content-Type": "application/json",
  };

  if (token) {
    headers.Authorization = `Bearer ${token}`;
  }

  const res = await fetch(url, {
    method: "POST",
    body: JSON.stringify(options),
    headers,
  });

  return res.json();
};

export const userApi = {
  checkUser,
  signIn,
  refreshUser,
  signUp,
  updateUser,
  addDeliveryAdress,
  removeDeliveryAdress,
  changeUserPassword,
  getFavoriteProducts,
  addFavoriteProduct,
  removeFavoriteProduct,
  getUserOrders,
  getUserCart,
  addProductToCart,
  addProductToCartPublic,
  addNewOrderForAuthorizedUsers,
  addNewOrderForNonAuthorizedUsers,
  restoreUserPassword,
  getOrderPaymentLink,
};
