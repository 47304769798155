/* eslint-disable no-console */
import { useEffect, useMemo, useRef, useState, useLayoutEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import PropTypes from "prop-types";

import getSortedOffersByPriceAndVolume from "../../../../../../utils/getSortedOffersByPriceAndVolume";
import { addProductToCart } from "../../../../../../store/user/cartThunks/cartThunks";
import saleTicketIcon from "../../../../../../assets/icons/png/sale_ticket-icon.png";

import ProductOffersHeader from "../../../ProductOffersHeader/ProductOffersHeader";
import DolyamiDropDown from "../DropDowns/DolyamiDropDown/DolyamiDropDown";
import CostCalculationDropDown from "../DropDowns/CostCalculationDropDown/CostCalculationDropDown";
import HelpDropDown from "../DropDowns/HelpDropDown/HelpDropDown";
import OffersList from "./OffersList/OffersList";

import styles from "./styles.module.scss";
import ModalFoundCheaper from "../../../../../../components/UI/ModalFoundCheaper/ModalFoundCheaper";
import cn from "classnames";
import useSearchParamsHandler from "../../../../../../utils/hooks/useSearchParamsHandler";
import { toast } from "react-toastify";
import { useSearchParams } from "react-router-dom";
import { metric } from "../../../../../../utils/yandexMetrics/yandexMetrics";
// import { IProduct } from "types/IProduct";
// import { IOffer } from "types/ICheckoutOrderItem";

// export interface ProductOffersProps {
//   setIsOpenRedirectModalCart: () => void;
//   offers: IOffer[];
//   product: IProduct;
//   isLoading: boolean;
// }
import { formatPrice } from "utils/helpers/formatedPrice";
import { handleYandexEcommerce } from "utils/yandexMetrics/yandexMetricsEcommerce";
import { YandexActionTypeEnum } from "types/YandexActionTypeEnum";

function ProductOffers(props) {
  const { cart } = useSelector((state) => state.user);
  const [isModalCheaper, setIsModalCheaper] = useState(false);
  const dispatch = useDispatch();
  const { searchParams } = useSearchParamsHandler();
  const { type } = Object.fromEntries(searchParams);
  const [, setSearchParams] = useSearchParams();


  const sortedOffers = useMemo(() => {
    if (props.isLoading || !props.product) return [];
    return getSortedOffersByPriceAndVolume(props.offers);
  }, [props.offers, props.isLoading, props.product]);

  const [currentOffer, setCurrentOffer] = useState({
    id: null,
    description: "",
    promotionPrice: null,
    price: null,
    percent: null,
    available: null,
  });
  const modalCheaperRef = useRef(null);

  useLayoutEffect(() => {
    if (sortedOffers.length > 0) {
      const firstOffer = sortedOffers[0];
      if (!type) {
        setSearchParams({ type: firstOffer.id });
        setCurrentOffer(firstOffer);
      } else {
        const selectedOffer = sortedOffers.find(offer => offer.id === type);
        if (!selectedOffer) {
          setCurrentOffer({
            id: null,
            description: "",
            promotionPrice: null,
            price: null,
            percent: null,
            available: null,
          });
          return;
        }
        setCurrentOffer(selectedOffer);
      }
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sortedOffers]);

  const onSelectOffer = (options) => {
    setCurrentOffer(options);
    setSearchParams({ type: options.id });
  };

  const wordsArr = props.product?.name.split(" ");
  const fiveWords = wordsArr?.splice(0, 5);
  const text = fiveWords?.lenght <= wordsArr?.length ? fiveWords?.join(" ") : fiveWords?.join(" ") + "...";


  // yandex ecommerce 'add'
  const yandexEcommerceAdd = async (id) => {

    const selectedOffer = sortedOffers.find(offer => offer.id === id);

    if (!selectedOffer) {
      return;
    }

    const productVariantString = `${selectedOffer.name}, ID: ${selectedOffer.id}`;
    const ecommerceAddData = {
      ecommerce: {
        currencyCode: "RUB",
        [YandexActionTypeEnum.add]: {
          products: [
            {
              id: props.product.id,
              name: props.product.name,
              category: props.product.category.name,
              brand: (props.product.brands && !!props.product.brands.length) ? props.product.brands[0].brand.name : "",
              price: currentOffer.promotionPrice ?? currentOffer.price,
              quantity: 1,
              variant: productVariantString
            }
          ]
        }
      }
    };

    await handleYandexEcommerce(ecommerceAddData);
  };

  const addProduct = async () => {
    if (process.env.REACT_APP_REDIRECT_CART === "true") return props.setIsOpenRedirectModalCart(true);
    if (!props.product || (!currentOffer.promotionPrice && !currentOffer.price)) {
      return;
    }

    try {
      const options = {
        id: currentOffer.id,
        count: 1
      };
      dispatch(addProductToCart([options])).unwrap();
      toast(`Товар "${text}" добавлен в корзину!`);
      metric.addToBasketMetric(currentOffer.promotionPrice, "RUB");

      // yandex ecommerce 'add'
      await yandexEcommerceAdd(options.id);
    } catch (err) {
      // eslint-disable-next-line no-console
      console.error("При добавлении товара в корзину произошла ошибка", err);
    }
  };
  const productInCart = useMemo(() => cart.find(e => e.offer.id === currentOffer.id), [cart, currentOffer]);

  const addProductAmount = async () => {
    try {
      const options = {
        id: currentOffer.id,
        count: productInCart.count + 1,
      };
      dispatch(addProductToCart([options]));
      toast(`Товар "${text}" добавлен в корзину!`);
      metric.addToBasketMetric(currentOffer.promotionPrice, "RUB");

      // yandex ecommerce 'add'
      await yandexEcommerceAdd(options.id);
    } catch (err) {
      // eslint-disable-next-line no-console
      console.error("При добавлении товара в корзину произошла ошибка", err);
    }
  };

  useEffect(() => {
    if (props.isLoading) return;
    const filteredOffered = sortedOffers.filter(offer => offer.id === Number(type));

    if (filteredOffered[0]) {
      return setCurrentOffer({
        id: filteredOffered[0]?.id || null,
        description: filteredOffered[0]?.name ?? "",
        promotionPrice: filteredOffered[0]?.price_sale ?? filteredOffered[0]?.akcia ?? null,
        price: filteredOffered[0]?.price ?? null,
        percent: filteredOffered[0]?.percent ?? null,
        available: sortedOffers[0]?.available ?? null,
      });
    }

    setCurrentOffer({
      id: sortedOffers[0]?.id || null,
      description: sortedOffers[0]?.name ?? "",
      promotionPrice: sortedOffers[0]?.price_sale ?? sortedOffers[0]?.akcia ?? null,
      price: sortedOffers[0]?.price ?? null,
      percent: sortedOffers[0]?.percent ?? null,
      available: sortedOffers[0]?.available ?? null,
    });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sortedOffers]);

  return (
    <div className={styles.root}>
      <ProductOffersHeader
        brandRewriteName={props.product?.brands[0].brand.rewrite_name}
        brandName={props.product?.brands[0].brand.name}
        productName={props.product?.name}
        currentOffer={currentOffer}
        isLoading={props.isLoading}
      />
      {!!sortedOffers.length && (
        <span className={styles["product_select-title"]}>
          Выберите вариант товара
        </span>
      )}
      <OffersList
        productId={props.product?.id}
        sortedOffers={sortedOffers}
        activeOfferId={currentOffer.id}
        onSelectOffer={onSelectOffer}
      />
      <div className={styles["product__buttons"]} itemScope itemType="https://schema.org/Offer">
        {currentOffer.available ? (
          <meta itemProp="availability" content="https://schema.org/InStock" />
        ) : (
          <meta itemProp="availability" content="https://schema.org/OutOfStock" />
        )}
        {!!sortedOffers.length && (
          <button className={styles["product__buttons__cheap"]} onClick={() => setIsModalCheaper(true)}>
            <img
              src={saleTicketIcon}
              alt="sale_ticket-icon"
              className={styles["product__buttons__cheap_icon"]}
            />
            <span className={styles["product__buttons__cheap_title"]}>
              Нашли дешевле? Сообщите нам!
            </span>
          </button>
        )}
        {!currentOffer.available && <span className={styles["product__available"]}>Товара нет в наличии</span>}
        <button
          className={cn(
            styles["product__buttons__cart"],
            { [styles["product__buttons__cart-disabled"]]: props.isLoading || !currentOffer.available }
          )}
          onClick={!productInCart ? addProduct : addProductAmount}
          disabled={props.isLoading || !currentOffer.available}
        >
          {productInCart ?
            <span className={styles["product__buttons__cart--title"]}>Добавить ещё</span> :
            <span className={styles["product__buttons__cart--title"]}>Добавить в корзину</span>
          }
          <span className={styles["product__buttons__cart_price"]}>
            {!currentOffer.promotionPrice
              ? !!currentOffer.price && `${currentOffer.price} ₽`
              : <>
                <span className={styles["product__buttons__cart_price--promo"]}>
                  {`${formatPrice(currentOffer.promotionPrice)} ₽ `}
                </span>
                <span className={styles["product__buttons__cart_price--main"]}>
                  {`${formatPrice(currentOffer.price)} ₽ `}
                </span>
              </>
            }
          </span>
        </button>
      </div>
      {process.env.REACT_APP_COMPONENT_ENABLE_DOLYAMI_DROP_DOWN === "true" && <DolyamiDropDown price={currentOffer.promotionPrice ?? currentOffer.price} />}
      <CostCalculationDropDown price={currentOffer.promotionPrice ?? currentOffer.price} isLoading={props.isLoading} />
      <HelpDropDown isLoading={props.isLoading} />
      {!props.isLoading && isModalCheaper && <ModalFoundCheaper onClose={() => setIsModalCheaper(false)} modalRef={modalCheaperRef} />}
    </div>
  );
}

const propTypes = {
  setIsOpenRedirectModalCart: PropTypes.func,
  offers: PropTypes.arrayOf(
    PropTypes.shape({
      akcia: PropTypes.number,
      bestseller: PropTypes.bool,
      "catalog_name": PropTypes.string,
      id: PropTypes.number,
      name: PropTypes.string,
      "new_label": PropTypes.bool,
      offerVolumes: PropTypes.arrayOf(
        PropTypes.shape({
          id: PropTypes.number,
          type: PropTypes.string,
          value: PropTypes.number,
          "value_type": PropTypes.string,
        })
      ),
      percent: PropTypes.number,
      price: PropTypes.number,
      "price_sale": PropTypes.number,
    }),
  ),
  product: PropTypes.shape({
    brands: PropTypes.arrayOf(
      PropTypes.shape({
        brand: PropTypes.shape({
          id: PropTypes.number,
          image: PropTypes.object,
          name: PropTypes.string,
          popular: PropTypes.bool,
          "rewrite_name": PropTypes.string,
        }),
      })
    ),
    category: PropTypes.shape({
      id: PropTypes.number,
      name: PropTypes.string,
    }),
    description: PropTypes.string,
    id: PropTypes.number,
    image: PropTypes.shape({
      width: PropTypes.number,
      height: PropTypes.number,
      link: PropTypes.string,
      mime: PropTypes.string,
      "new_file_name": PropTypes.string,
      size: PropTypes.number,
    }),
    name: PropTypes.string,
    "product_rewrite_name": PropTypes.string,
    reducedImage: PropTypes.shape({
      width: PropTypes.number,
      height: PropTypes.number,
      link: PropTypes.string,
      mime: PropTypes.string,
      "new_file_name": PropTypes.string,
      size: PropTypes.number,
    }),
    vendorCode: PropTypes.string,
  }),
  isLoading: PropTypes.bool,
};

ProductOffers.propTypes = propTypes;

export default ProductOffers;