import { useMemo } from "react";
import PropTypes from "prop-types";

import OrderStringItem from "../../../../../../components/UI/OrderStringItem";
// import CustomButton from "../../../../../../components/CustomButton";

import { PAYMENT_STATUSES } from "../../../../../../utils/constants";

import styles from "./styles.module.scss";
import { formatPrice } from "utils/helpers/formatedPrice";

const OrderItemsData = (props) => {
  const prices = useMemo(() => {
    const reducedMaxPrice = props.offers.reduce((acc, cur) => {
      return acc + (cur.site_price * cur.count);
    }, 0);
    const reducedSalePrice = props.offers.reduce((acc, cur) => {
      return acc + (cur.sale_price * cur.count);
    }, 0);
    const salePriceAndDelivery = reducedSalePrice + (props.deliveryPrice ?? 0);
    return {
      reducedMaxPrice,
      salePriceAndDelivery,
      reducedSalePrice,
    };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.offers]);

  // const showPaymentButton = props?.orderStatusId === 2;
  // TODO: add payment button logic
  return(
    <div className={styles.root}>
      <div className={styles.data}>
        <OrderStringItem
          title="Текущий статус оплаты:"
          subtitle={PAYMENT_STATUSES[props.paymentStatus]?.title ?? "-"}
          contanerClassname={styles.data__status}
        />
        <OrderStringItem
          title="Способ оплаты:"
          subtitle={props.paymentMethod ?? "-"}
          contanerClassname={styles["data__payment-method"]}
        />
      </div>
      <div className={styles.data}>
        <OrderStringItem
          title="Стоимость товаров:"
          subtitle={`${formatPrice(prices.reducedMaxPrice)} ₽`}
          contanerClassname={styles.data__price}
        />
        <OrderStringItem
          title="Скидка:"
          subtitle={`${formatPrice(prices.reducedMaxPrice - prices.reducedSalePrice)} ₽`}
          contanerClassname={styles.data__price}
          subtitleClassname={styles["data__price--black"]}
        />
        <OrderStringItem
          title="Доставка:"
          subtitle={!props.deliveryPrice ? "" : `${formatPrice(props.deliveryPrice)} ₽`}
          contanerClassname={styles.data__price}
          subtitleClassname={styles["data__price--black"]}
        />
        <OrderStringItem
          title="Всего к оплате:"
          subtitle={`${formatPrice(prices.salePriceAndDelivery)} ₽`}
          contanerClassname={styles.data__price}
          subtitleClassname={styles["data__price--black"]}
        />
        {/* {showPaymentButton &&
        <CustomButton
          title="Оплатить"
          className={styles.button}
        />} */}
      </div>
    </div>
  );
};

const propTypes = {
  offers: PropTypes.array.isRequired,
  deliveryPrice: PropTypes.number,
  paymentStatus: PropTypes.string,
  paymentMethod: PropTypes.string,
  orderStatusId: PropTypes.number,
};

OrderItemsData.propTypes = propTypes;

export default OrderItemsData;