/* eslint-disable camelcase */
import { FC } from "react";
import { setProviderInfo, setRecipient, setUserDeliveryAddress } from "../../../../../../../store/user/user";
import { WAY_TO_GET } from "../../../../../../../utils/constants";
import styles from "./styles.module.scss";
import CustomButton from "../../../../../../../components/CustomButton/CustomButton";
import { ProvidersInfo, SelectedProvider, SelectedProviderData, ToDoorDetail, ToPointDetail } from "types/IProviderInfo";
import { useAppDispatch, useAppSelector } from "store/reduxHooks";
import { objectEntries } from "utils/helpers/typeGuardObjEntries";
import { IProviderInfoStore } from "types/IProviderInfoStore";
import { Recipient } from "types/IRecipient";

interface ProviderInfoProps {
  selectedProviderData: SelectedProviderData;
  providersInfo: ProvidersInfo;
  onCloseModal: () => void;
  returnToPvzSelect: () => void;
  onClose: () => void;
  selectedProvider: SelectedProvider;
  setCity: (city: string) => Promise<void>;
  setSelectedProvider: () => void;
  showProviderInfo: boolean;
}

const ProviderInfo: FC<ProviderInfoProps> = ({
  onCloseModal,
  providersInfo,
  returnToPvzSelect,
  selectedProviderData,
}) => {
  const checkoutData = useAppSelector((state) => state.user.checkoutData);
  const dispatch = useAppDispatch();

  const acceptAddress = () => {
    const wayToGetKey = objectEntries(WAY_TO_GET).find((item) => {
      return item[1] === checkoutData.wayToGet;
    })?.[0];

    if (!wayToGetKey) {
      return;
    }

    const findedItem = providersInfo[wayToGetKey].find(
      (item: ToDoorDetail | ToPointDetail) => item.deliveryProvider === selectedProviderData.deliveryProviderType
    );

    const deliveryType =
      checkoutData.wayToGet === WAY_TO_GET.toDoor ? "delivery" : "pickup";

    dispatch(setUserDeliveryAddress(selectedProviderData.address));
    const providerInfo: IProviderInfoStore = {
      provider: findedItem.deliveryProvider,
      amount: findedItem.deliveryCost,
      daysMax: findedItem.daysMax,
      daysMin: findedItem.daysMin,
      type: deliveryType,
      // eslint-disable-next-line camelcase
      pvz_id: deliveryType === "pickup" ? selectedProviderData.pvz_id : null,
    };

    if (deliveryType === "pickup" && "addresses" in findedItem) {
      const toPointDetail = findedItem as ToPointDetail;
      if (toPointDetail.addresses) {
        const updatedAddresses = toPointDetail.addresses.map((address, index) => {
          if (index === 0) {
            return {
              ...address,
              // eslint-disable-next-line camelcase
              pvz_id: selectedProviderData.pvz_id,
            };
          }
          return address;
        });

        toPointDetail.addresses = updatedAddresses;
      }
    }

    const updatedRecipient: Recipient = {
      ...checkoutData.recipient,
      address: selectedProviderData.address,
      // eslint-disable-next-line camelcase
      pvz_id: deliveryType === "pickup" ? selectedProviderData.pvz_id : null,
    };

    dispatch(setProviderInfo(providerInfo));
    dispatch(setRecipient(updatedRecipient));
    onCloseModal();
  };

  return (
    <div className={styles.root}>
      <h2 className={styles.title}>Как доставить заказ?</h2>
      <div className={styles["delivery-info"]}>
        <h2 className={styles["delivery-info__title"]}>Выбранный ПВЗ</h2>
        <p className={styles["delivery-info__provider"]}>
          {selectedProviderData?.deliveryProvider}
        </p>
        <p className={styles["delivery-info__schedule"]}>
          {selectedProviderData?.timeTable}
        </p>
        <div className={styles["delivery-info__address-wrapper"]}>
          <p className={styles["delivery-info__address-description"]}>
            {selectedProviderData?.address}
          </p>
        </div>
      </div>
      <div className={styles["buttons-wrapper"]}>
        <CustomButton
          title="Подтвердить выбор"
          onClick={acceptAddress}
          className={styles["select-button"]}
        />
        <button
          onClick={returnToPvzSelect}
          className={styles["additional-button"]}
        >
          Вернуться к выбору адреса
        </button>
      </div>
    </div>
  );
};

export default ProviderInfo;