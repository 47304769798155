import { useMemo, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { useFormik } from "formik";
import { toast } from "react-toastify";

import { userApi } from "../../../../api/userApi";
import { changePasswordSchema } from "../../../../schemas/changePasswordSchema";
import { SOCIAL_MEDIA } from "../../../../utils/constants/authSocialMedia";

import CustomAuthInput from "../../../../components/CustomAuthInput";
import CustomButton from "../../../../components/CustomButton";
import AnchorsBlock from "../components/AnchorsBlock";
import Notifications from "./Notifications/Notifications";
import CustomNotification from "../../../../components/CustomNotification";
import SocialMediaLink from "./SocialMediaLink";

import cn from "classnames";
import styles from "./styles.module.scss";
import { isError } from "store/user/userThunks/userThunks";

const PASSWORD_VALUES = [
  {
    id: 0,
    title: "Старый пароль",
    name: "oldPassword",
    inputType: "password",
  },
  {
    id: 1,
    title: "Новый пароль",
    name: "newPassword",
    inputType: "password",
  },
];

const UserSettings = () => {
  const { deviceType } = useSelector((state) => state.user);

  const [showErrorsOnSubmit, setShowErrorsOnSubmit] = useState(false);
  const [showUserDataNotification, setShowUserDataNotification] = useState(false);

  const passwordsRef = useRef(null);
  const socialMediaRef = useRef(null);
  const notificationRef = useRef(null);

  const anchors = useMemo(() => {
    return [
      {
        id: 0,
        title: "Изменение пароля",
        ref: passwordsRef,
        offsetTop: 200,
      },
      {
        id: 1,
        title: "Привязка к социальным сетям",
        ref: socialMediaRef,
        offsetTop: 90,
      },
      {
        id: 2,
        title: "Настройка уведомлений",
        ref: notificationRef,
        offsetTop: 30,
      },
    ];
  }, []);

  const formik = useFormik({
    initialValues: {
      oldPassword: "",
      newPassword: "",
    },
    validationSchema: changePasswordSchema,
    onSubmit: async () => {
      try {
        const res = await userApi.changeUserPassword({
          "old_password": formik.values.oldPassword,
          "new_password": formik.values.newPassword,
        });

        if (isError(res)) {
          throw new Error(res.message);
        }

        setShowUserDataNotification(true);

        setTimeout(() => {
          setShowUserDataNotification(false);
        }, 2000);

        return toast.success(res.response);
      } catch (err) {
        return toast(err.message);
      }
    },
  });

  const onSubmitHandler = () => {
    setShowErrorsOnSubmit(true);
    formik.handleSubmit();
  };

  return(
    <section className={styles.root}>
      <section className={styles.wrapper}>
        <form
          ref={passwordsRef}
          method="post"
          onSubmit={onSubmitHandler}
          className={styles.form}
        >
          <h3 className={cn(styles.title, styles.form__title)}>
            Изменение пароля
          </h3>
          {PASSWORD_VALUES.map((item) => {
            return (
              <CustomAuthInput
                key={item.id}
                name={item.name}
                inputType={item.inputType}
                placeholder={item.title}
                value={formik.values[`${item.name}`]}
                onChange={formik.handleChange}
                errorValue={formik.errors[`${item.name}`]}
                showErrorsOnSubmit={showErrorsOnSubmit}
                containerClassName={styles.form__input}
              />
            );
          })}
          <CustomButton
            title="Сохранить"
            onClick={onSubmitHandler}
            type="button"
            className={styles.form__button}
          />
        </form>
        <div
          ref={socialMediaRef}
          className={styles["social-media"]}
        >
          <h3 className={cn(styles.title, styles["social-media__title"])}>
            Привязка к социальным сетям
          </h3>
          <div className={styles["social-media__items"]}>
            {SOCIAL_MEDIA.map((item) => {
              return (
                <div key={item.id} className={styles["social-media__item"]}>
                  <div className={styles["social-media__type"]}>
                    <div className={styles["social-media__icon-wrapper"]}>
                      <img
                        src={item.img}
                        alt={item.alt}
                        className={styles["social-media__icon"]}
                      />
                    </div>
                    <span className={styles["social-media__subtitle"]}>
                      {item.title}
                    </span>
                  </div>
                  <SocialMediaLink
                    service={item.service}
                    vendorName={item.vendorName}
                  />
                </div>
              );
            })}
          </div>
        </div>
        <Notifications notificationRef={notificationRef} />
      </section>
      {!deviceType.isMobile && <AnchorsBlock anchors={anchors} />}
      <CustomNotification showNotification={showUserDataNotification} />
    </section>
  );
};

export default UserSettings;